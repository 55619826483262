import React, { useState } from "react";
import Alert from "../components/alert/Alert";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import aux from "../utils/auxiliar";
import moment from "moment";
import API from "../API/APIUser";

export const PontoContext = React.createContext({
  loadPonto: async () => { }
});

export const PontoProvider = (props) => {

  async function loadPonto() {
    try {

      const result = await API.getEspelhoPonto();

      if (result.length === 0) {
        return '--';
      }

      const { entrada, saidaAlmoco, retornoAlmoco, saida } = result[0];

      let formattedHour;
      if (!aux.isZeroDate(saida.horario)) {
        formattedHour = moment(saida.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      } else if (!aux.isZeroDate(retornoAlmoco.horario)) {
        formattedHour = moment(retornoAlmoco.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      } else if (!aux.isZeroDate(saidaAlmoco.horario)) {
        formattedHour = moment(saidaAlmoco.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      } else if (!aux.isZeroDate(entrada.horario)) {
        formattedHour = moment(entrada.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      } else {
        formattedHour = '--'
      }

      return formattedHour;

    } catch {
      return '--';
    }
  }


  return (
    <PontoContext.Provider value={{ loadPonto }}>
      {props.children}
    </PontoContext.Provider >
  )
}