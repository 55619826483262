import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import APINews from '../../API/APINews';
import APIDocs from '../../API/APIDocs';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Mock,
  NewsContainer,
  NewsList,
  NewsPageLink,
  NewsSection,
  Shortcut,
  ShortcutContainer,
  SpanNoNews
} from './style';

import NewsCard from '../../components/newsCard';
import Post from '../../components/post';
import { PontoContext } from '../../contexts/ponto';

export default function Home() {
  const [newsList, setNewsList] = useState([]);
  const [newsListToShow, setNewsListToShow] = useState([]);
  const [openPost, setOpenPost] = useState(null);

  const [pendingDocs, setPendingDocs] = useState(null);
  const [lastRecord, setLastRecord] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const history = useHistory();
  const ref = useRef();

  const { loadPonto } = useContext(PontoContext);

  useEffect(() => {
    initPage();
  }, []);

  async function initPage() {
    try {
      setIsLoading(true);
      await loadNews();
      await loadDocs();

      const ponto = await loadPonto();
      setLastRecord(ponto);
      setIsLoading(false);
    } catch (error) {
      throw new Error(`initPage: ${initPage}`)
    } finally {
      setIsLoading(false);
    }

  }

  async function loadNews() {
    try {
      setLoadingError(false);
      setNewsList([]);

      const result = await APINews.getNoticias(1);

      if (result.err) {
        return;
      }

      setNewsList(result);
      setNewsListToShow(result.slice(0, 3));

    } catch {
      setLoadingError(true);
    }
  }

  async function loadDocs() {
    try {
      const result = await APIDocs.getDocs();
      if (result.err) {
        setPendingDocs('--');
      } else {
        const nonSignedDocs = result.filter(doc => doc.controlaAssinatura && !doc.assinado && !doc.rejeitado);
        setPendingDocs(nonSignedDocs.length || "--");
      }
    } catch {

    }
  }


  function goToNews() {
    history.push({ pathname: '/noticias', state: { newsList } })
  }

  function goToPonto() {
    history.push({ pathname: '/ponto' })
  }

  function goToDocs() {
    history.push({ pathname: '/documentos' })
  }

  function showPost(post) {
    setOpenPost(post)
  }

  function closePost() {
    setOpenPost(false);
  }

  return (
    <>
      {!!openPost &&
        <Post
          data={openPost}
          closePost={closePost}
        />
      }
      <Container ref={ref}>
        <ShortcutContainer>

          <Shortcut onClick={goToDocs}>
            <h3>Documentos não vistos</h3>
            <p>{pendingDocs || '--'}</p>
          </Shortcut>

          <Shortcut className='mobileOnly' onClick={goToPonto}>
            <h3>Ponto diário</h3>
            <p>{lastRecord || '--'}</p>
          </Shortcut>
        </ShortcutContainer>

        <NewsSection>
          {isLoading && <Mock count={3} />}

          {!isLoading && newsListToShow.length > 0 && (
            <NewsList>
              {newsListToShow.map(item => <li><NewsCard news={item} /></li>)}
            </NewsList>
          )}

          {
            !isLoading && newsListToShow.length === 0 && loadingError && (
              <p>Algo deu errado no carregamento das notícias.<a onClick={loadNews}>Tentar novamente?</a></p>
            )
          }

          {newsListToShow.length === 0 && <SpanNoNews>Nenhuma notícia encontrada</SpanNoNews>}

        </NewsSection>
      </Container>
    </>
  )
}